import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [0];

export const dictionary = {
		"/(index)": [~17],
		"/(misc)/aboutme": [18],
		"/achievements": [27],
		"/achievements/[id]": [28],
		"/arena": [~29],
		"/callback": [30],
		"/chess": [31],
		"/contactus": [32],
		"/create": [33],
		"/(trivia)/customgame": [22],
		"/daily-quest": [~34],
		"/(auth)/delete": [8],
		"/(dev)/dev": [~14],
		"/(dev)/dev/geo": [~15],
		"/(trivia)/duel": [~23],
		"/(trivia)/duel/[roomId]": [~24],
		"/(misc)/faq": [19],
		"/(auth)/forgot-password": [9],
		"/friends": [~35,[3]],
		"/friends/pending": [~36,[3]],
		"/friends/requests": [~37,[3]],
		"/friends/suggestions": [~38,[3]],
		"/gr33n": [39,[4]],
		"/gr33n/2": [40,[4]],
		"/gr33n/contact": [41,[4]],
		"/gr33n/merch": [42,[4]],
		"/gr33n/music": [43,[4]],
		"/leaderboards": [~44],
		"/leaderboards/friends": [~45],
		"/placement": [~46],
		"/(misc)/privacy": [20],
		"/(auth)/reset-password": [10],
		"/restaurant": [47,[5]],
		"/restaurant/lunch": [48,[5]],
		"/restaurant/menu": [49,[5,6]],
		"/search": [~50],
		"/search/[query]": [~51],
		"/shop": [~52],
		"/shop/cancel": [53],
		"/shop/success": [~54],
		"/(auth)/signin": [~11],
		"/(auth)/signout": [~12],
		"/(auth)/signup": [~13],
		"/social/friends": [55,[7]],
		"/social/last_match": [56,[7]],
		"/social/recent_players": [57,[7]],
		"/(misc)/terms": [21],
		"/(dev)/test/img": [16],
		"/tree": [58],
		"/(trivia)/trivia/train": [~25,[2]],
		"/users": [~62],
		"/user": [~59],
		"/user/[id]": [~60],
		"/user/[id]/update": [61],
		"/(trivia)/world": [26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';